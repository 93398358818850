@page {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}
body>* {
  display: none;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font: 0.34cm/.34cm Calibri Helvetica, Arial, sans-serif;
}
main {
  display: block;
  &,
  * {
    width: 100%!important;
    height: 100%!important;
    margin: 0;
    padding: 0;
  }
  .pages {
    list-style-type: none;
    li {
      page-break-after: always;
    }
  }
}
